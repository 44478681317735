














import { Component, Vue, Watch } from 'vue-property-decorator'
import Tabs from '@/app/ui/components/Tabs/index.vue'
import controller from '@/app/ui/controllers/PayrollController'
import dayjs from 'dayjs'
import dayjsID from 'dayjs/locale/id'
import { PeriodRule } from '@/domain/entities/Payroll'

@Component({
  components: {
    Tabs,
  },
})
export default class EditManageDataMaster extends Vue {
  controller = controller
  currentTab = 0

  currentPeriod = ''

  itemTabs = [
    { label: 'Basic Fee', value: 0 },
    { label: 'Bonus Mekanisme', value: 1 },
    { label: 'Penarikan Dana', value: 2 },
    { label: 'Update History', value: 3 },
  ]

  created(): void {
    if (this.$route.name !== this.getTabRoute(0)) {
      this.$router.push({ name: this.getTabRoute(0) })
    }
    this.getCurrentPeriod()
  }

  private getCurrentPeriod(): void {
    const res = controller.dataPayrollList.find(
      item => item.id === Number(this.$route.params.periodId)
    )
    this.currentPeriod = `${this.formatDateToID(
      'DD MMMM',
      res?.startAt
    )} - ${this.formatDateToID('DD MMMM YYYY', res?.endAt)}`
  }

  private formatDateToID(format: string, date?: string): string {
    if (!date) return ''
    return dayjs(date)
      .locale(dayjsID)
      .format(format)
  }

  private onTabsClick(tab: number): void {
    this.currentTab = tab
    this.itemTabs.filter(item => {
      if (item.value === this.currentTab)
        this.$router.push({ name: this.getTabRoute(item.value) })
    })
  }

  private getTabRoute(key: number): string {
    if (key === 0) {
      return 'BasicFeeDetail'
    }
    if (key === 1) {
      return 'BonusMechanismDetail'
    }
    if (key === 2) {
      return 'FundsWithdrawalDetail'
    } else {
      return 'UpdateHistoryDetail'
    }
  }
}
